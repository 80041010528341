/* Global */
/* namesapce: app */
.app-footer {
  text-align: center;
  height: 72px;
}

.app-center {
  text-align: center;
}

/* namesapce: editable */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

/* Admin Template */
/* namespace: adm */
#admin-template .logo {
  width: 120px;
  float: left;
}

#admin-template .logo img {
  width: 100%;
}

#admin-template .admin-content {
  min-height: calc(100vh - 72px - 65px);
}

/* Front Template */
/* namespace: front */
#front-template .logo {
  width: 120px;
  float: left;
}

#front-template .logo img {
  width: 100%;
}

#front-template .front-content {
  min-height: calc(100vh - 72px - 65px);
  width: 100%;
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px 20px;
}

/* Center Template */
/* namespace: cen */
#center-template .cen-content {
  height: calc(100vh - 72px);
}

#center-template .cen-login {
  height: 100%;
  padding: 10px;
}
.bagLoc {
  /* height: 60px; */
  /* justify-content: center; */
  /* align-items: center; */
  min-height: 50px;
  font-size: 14px;
  line-height: 20px;
  /* -webkit-box-shadow: 0 15px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 15px 10px rgba(0,0,0,0.1); */
  /* box-shadow: 0 15px 10px rgba(0,0,0,0.1); */
  box-shadow: 0 1px 7px 0 rgba(0,0,0,0.1);
  /* text-align: center; */
  margin: 3px;
  background: white;
  border-radius: 4px;
  padding: 7px;
  /* display: flex; */
}